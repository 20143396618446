.cal-container {
    display: flex;
    width: 80%;
    margin-left: 10%;
    height: 300px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid maroon;
}

.left-flex {
    flex: 50%;
    background-color: white;
}

.right-flex {
    flex: 50%;
    background-color: white;
}

.cal-details {
    display: flex;
    width: 80%;
    margin-left: 10%;
}

.cal-details-parts {
    flex: 47.5%;
    /* height: 60px; */
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid maroon;
    text-align: center;
}

.cal-details-spacer {
    flex: 5%;
}

.detail-label {
    display:inline-block;
    margin-right: 10px;
}

.detail-label-1 {
    display:inline-block;
    margin-right: 40px;
}

#date_calibrated {
    width: auto;
}

#calibration_type {
    /* margin-left: 10%; */
    margin-right: 5px;
}

.comment-container {
    width: 80%;
    margin-left: 10%;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid maroon;
}

.comment-box {
    display: block;
    width: 100%;
    height: 200px;
    margin-top: 10px;
}

.measurement_type {
    width: 20%;
    display: inline-block;
}

.standards_used {
    width: 25%;
    display: inline-block;
}

#holder-fields {
    width: 20%;
    display: inline;
    border-radius: 0;
}

.table-fillers {
    width: 100%;
}

.submit-button {
    width: 80%;
    margin-left: 10%;
}

#landing-button {
    margin-top: 10px;
    margin-left: 10%;
    width: 80%;
}

.keep-buttons-together {
    width: 400px;
    text-align: center;
    display: inline-block;
}