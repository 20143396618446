.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-logo {
  margin-left: 10px;
}

.right-nav {
  margin-right: .5%;
}

#username {
  font-weight: bold;
}

.signedIn {
  margin-right: 10px;
  margin-top: 6px;
}

.dropdownLink {
  color: #dee2e6;
  text-decoration: none;
  margin-left: 20px;
  
}
.dropdownLink:hover {
    color: grey;
}

.page-selection {
    margin-left: 15px;
    margin-top: 5px;
    border-left: 1px solid rgba(255,255,255,.55);
}

/* #add-update-dropdown {
  margin-left: 0px;
  border-left: 1px solid rgba(255,255,255,.55);
} */

#brand-name {
  margin-left: -5px;
  margin-right: 10px;
}


.page-header {
  margin-top: 1%;
  text-decoration: underline;
}

input[type="text"], input[type="date"] {
  width: 100%;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  display: inline-block;  
  align-self: left;
  border: 1px solid black;
}

.select-fields {
    border: 1px solid black;
    border-radius: 4px;
}

#comments {
    width: 100%;
    height: 200px;
    border-radius: 4px;
    border: 1px solid black;
}

.divider-div { 
    display: flex;
    width: 90%;
    margin-left: 5%;
}

.used-items {
    flex: 40%;
    padding-top: 5%;
    padding-left: 5%;
    height: 600px;
}


.instrument-container {
    flex: 60%;
}

.flex-container {
    display: flex;
}

.column_1 {
    width: 45%;
    margin-right: 10%;
}

.column_2 {
    width: 45%;
}
  
#comment-box {
    width: 100%;
}

#user-feeback {
    display: inline-block;
}

#home-button {
    margin-left: 10px;
    margin-right: 10px;
}

#serial_number {
    width: 70%;
    display: inline-block;
}

#sn-button {
    display: inline-block;
    width: 25%;
    margin-left: 5%;
}

#img-background {
    background-image: url("../media/home_page.jpg");
    background-size: cover;
    margin-top: 2%;
    background-repeat: no-repeat;
    height:100vh;
    width: 100vw;
    text-align: center;
}

#landing-message {
    text-align: center;
    width: 40%;
    min-height: 200px;
    margin-top: 10%;
    margin-left: 30%;
    padding: 3% 0;
    background-color: white;
    border: 5px solid #AF282E;
    border-radius: 10px;
    color: black;
    font-family: 'Proxima Nova Regular';
}
