.landing-grid {
    height: 400px;
    width: 80%;
    margin-left: 10%;
    background-color: white;
    border-radius: 10px;
    border: 2px solid maroon;
}

.instrument-overview {
    display: flex;
    width: 80%;
    margin-left: 10%;
    /* height: 600px; */
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid maroon;
    flex-wrap: wrap;
}

.instrument-left {
    flex: 50%;
    background-color: white;
}

.instrument-right {
    flex: 50%;
    background-color: white;
}

.instrument-comments {
    width: 90%;
    background-color: lightgray;
    height: 5em;
}

#add-cal-button, #edit-cal-button, #cal-log-button, #cal-book-button, #blank-cal-button {
    width: 100%;
}

#page-header {
    margin-left: 10%;
    display: inline-block;
}

.type-select {
    border: 1px solid black;
    border-radius: 4px;
    display: inline-block;
    margin-left: 20px;
    width: 200px;
}

.instrument-grid-Inactive {
    background-color: #cb9ed9;
}

.due-date-red {
    background-color: #db5a6b;
}

.due-date-green {
    background-color: #98fb98;
}

.due-date-yellow {
    background-color: #f7f748;
}

#update_instrument_button {
    flex: 1;
    margin-top: 20px;
    height: 3em
}

#assigned_to_landing {
    width: 90%;
}

#landing-departments {
    width: 90%
}